<template>
  <div>
    <PageHeader :key="globalLanguage" :items="items" />
    <validation-observer ref="simpleRules">
      <div class="card">
        <div class="card-body">
          <b-row>
            <b-col cols="12" lg="6" md="6" class="mb-1">
              <validation-provider
                #default="{ errors }"
                name="Name"
                rules="required"
              >
                <TextInput v-model="name" :key="name" :label="$t('Name')" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-col>
            <b-col cols="12" lg="6" md="6" class="mb-1">
              <validation-provider
                #default="{ errors }"
                name="Status"
                rules="required"
              >
                <label class="input-label form-label"
                  ><span style="color: red">*</span>&nbsp;{{ $t("Customer") }}
                </label>
                <MultiSelectInput
                  v-model="companyId"
                  :key="companyId"
                  :options="customers"
                  label="companyName"
                  trackBy="id"
                  moduleName="customers"
                  :multiple="false"
                  ><template #option="{ props }">
                    <div>
                      <p>
                        {{ props.option.companyNumber }} -
                        {{ props.option.companyName }}
                      </p>
                    </div>
                  </template>
                </MultiSelectInput>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-col>
            <b-col cols="12" lg="6" md="6" class="mb-1">
              <input @change="handleFileChange" ref="fileInput" type="file" />
            </b-col>
          </b-row>
          <b-row class="mt-2">
            <b-col cols="12" lg="5" md="5" class="mb-1">
              <div
                class="d-flex align-items-center"
                v-for="(value, key) in config"
                :key="key"
              >
                <div class="flex-grow-1" @click="setCodeString(value, key)">
                  <TextInput
                    @input="setKey"
                    :value="key"
                    :shouldHighlight="selectedConfig === key"
                    class="cursor-pointer"
                  />
                </div>
                <feather-icon
                  @click="removeConfig(key)"
                  class="cursor-pointer mx-3"
                  icon="TrashIcon"
                />
              </div>
              <b-button
                variant="secondary"
                @click="addNewConfig"
                class="py-1 px-1 mt-2"
              >
                {{ $t("Add new config entry") }}
              </b-button>
            </b-col>
            <b-col cols="12" lg="7" md="7" class="mb-1">
              <label class="font-bold" for="">JSON:</label>
              <MonacoEditor
                @contentChange="setConfig($event)"
                :readOnly="false"
                :codeString="codeString"
                :height="'500px'"
                :language="'json'"
              />
            </b-col>
          </b-row>
        </div>
      </div>
      <div class="d-flex align-items-center justify-content-end gap-1">
        <router-link
          to="/document-templates"
          class="d-flex align-items-center gap-1 btn btn-secondary"
        >
          <feather-icon icon="XIcon" size="12" />
          <span>{{ $t("Cancel") }}</span>
        </router-link>
        <loading-button
          @click="downloadOriginalFile"
          :loading="isLoading"
          class="btn-green"
          >{{ $t("Download Original") }}</loading-button
        >
        <loading-button
          v-if="$can(`${$route.meta.permission}.list`)"
          @click="$router.push(`/document-templates/${$route.params.id}/show`)"
          :loading="isLoading"
          class="secondary-btn"
          >{{ $t("View Document") }}</loading-button
        >
        <loading-button
          v-if="$can(`${$route.meta.permission}.create`)"
          @click="update"
          :loading="isLoading"
          :class="{ 'cursor-not-allowed': isFormInvalid }"
          class="secondary-btn"
        >
          {{ $t("Save and Proceed") }}
        </loading-button>
      </div>
    </validation-observer>
  </div>
</template>

<script>
import PageHeader from "@/components/PageHeader.vue";
import { mapGetters } from "vuex";
import { required, email } from "@validations";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import MultiSelectInput from "@/components/MultiSelectInput.vue";
import MonacoEditor from "@/components/MonacoEditor.vue";
import TextInput from "@/components/TextInput.vue";
import LoadingButton from "@/components/LoadingButton.vue";

export default {
  computed: {
    ...mapGetters(["errors", "isLoading", "showLoader"]),
    ...mapGetters("customers", ["customers"]),
    ...mapGetters("documentService", ["documentServices", "count"]),
    items() {
      return [
        {
          text: "Dental Twin",
          to: "/home",
        },
        {
          text: this.$t("Settings"),
          to: "/settings",
        },
        {
          text: this.$t("Document Templates"),
          to: "/document-templates",
        },
        {
          text: this.$t("Edit"),
          active: true,
        },
      ];
    },
    isFormInvalid() {
      return !this.companyId;
    },
  },
  components: {
    TextInput,
    MultiSelectInput,
    PageHeader,
    MonacoEditor,
    ValidationProvider,
    ValidationObserver,
    LoadingButton,
  },
  remember: "form",
  data() {
    return {
      selectedConfig: null,
      config: {},
      codeString: "",

      name: "",
      companyId: "",
      documentType: "",
      form: new FormData(),
    };
  },
  async mounted() {
    this.loadItems();
  },
  methods: {
    /**
     * Adds a new empty config entry with a default key
     */
    addNewConfig() {
      const defaultKey = "New Config " + (Object.keys(this.config).length + 1);
      this.$set(this.config, defaultKey, {});
      this.selectedConfig = defaultKey;
      this.setCodeString({}, defaultKey);
    },
    /**
     * triggered when config key name is changed
     * @param {event} change event
     */
    setKey(event) {
      try {
        const previousConfig = this.config[this.selectedConfig];
        delete this.config[this.selectedConfig];
        this.config[event.target.value] = previousConfig;
        this.selectedConfig = event.target.value;
      } catch (e) {}
    },
    /**
     * removes 'key' from config variable
     * @param {key} key to be removed
     */
    removeConfig(key) {
      delete this.config[key];
      if (this.selectedConfig === key) {
        this.codeString = "";
        this.json = "";
        this.selectedConfig = null;
      }
    },
    /**
     * sets the changed config json value to the config variable
     * @param {*} event
     */
    setConfig(event) {
      if (this.selectedConfig && this.config[this.selectedConfig])
        this.config[this.selectedConfig] = event;
    },
    /**
     * sets codeString, selectedConfig when we click on a config key
     * @param {value} value to be set to codeString
     * @param {key} key to be selected
     */
    setCodeString(value, key) {
      console.log(value, key);
      if (!!this.selectedConfig) {
        try {
          this.config[this.selectedConfig] = JSON.parse(
            this.config[this.selectedConfig]
          );
        } catch (e) {}
      }
      this.codeString = JSON.stringify(value);
      this.json = "";
      this.selectedConfig = key;
    },
    async loadItems() {
      try {
        this.$store.commit("showLoader", true);
        const documentResponse = await this.$store.dispatch(
          "documentService/show",
          this.$route.params.id
        );
        this.name = documentResponse?.data?.name;
        this.companyId = documentResponse?.data?.company_id;
        this.documentType = documentResponse?.data?.type;
        this.config = documentResponse?.data?.config ?? {};
        if (typeof this.config !== "object" || this.config instanceof Array) {
          this.config = {};
        }
        this.selectedConfig = Object.keys(this.config)?.[0] ?? null;
        if (this.selectedConfig) {
          this.setCodeString(
            this.config[this.selectedConfig],
            this.selectedConfig
          );
        }
        if (!this.customers.length)
          await this.$store.dispatch("customers/list", {
            page: 1,
            perPage: 25,
          });
        let company =
          this.customers.find((company) => company.id === this.companyId) ?? "";
        let response = null;
        if (!company && this.companyId) {
          response = await this.$store.dispatch(
            "customers/show",
            this.$route.query.companyId
          );
          company = response?.data?.modelData ?? "";
        }
        this.companyId = company;
      } catch (e) {
        console.log(e);
      } finally {
        this.$store.commit("showLoader", false);
      }
    },
    // calls the download orignal file API from document service and downloads the file
    async downloadOriginalFile() {
      try {
        this.$store.commit("isLoading", true);
        const response = await this.$store.dispatch(
          "documentService/downloadOriginalFile",
          {
            id: this.$route.params.id,
            documentType: this.documentType,
            filename: this.name,
          }
        );
      } catch (e) {
        console.log(e);
      }
    },
    async handleFileChange(event) {
      const file = event?.target?.files?.[0];
      this.form.append("upload_files", file);
    },
    async update() {
      try {
        if (this.companyId?.id) {
          for (let key in this.config) {
            try {
              this.config[key] = JSON.parse(this.config[key]);
            } catch (e) {}
          }
          this.form.append(
            "data",
            JSON.stringify({
              id: this.$route.params.id,
              company_id: this.companyId?.id,
              config: this.config,
            })
          );
        } else {
          this.$store.commit("errors", {
            companyId: "Company is a required field",
          });
          return;
        }
        this.$store.commit("isLoading", true);
        await this.$store.dispatch("documentService/create", this.form);
        this.$refs.fileInput.value = null;
        this.loadItems();
      } catch (e) {
        console.log(e);
      }
    },
  },
};
</script>
